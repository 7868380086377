import {AfterViewInit, Component, ElementRef, OnInit, SecurityContext} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BannerMessageModel} from '../../models/config-settings';
import {CreateBannerMessage, UpdateBannerMessage} from '../../actions/banner-message/banner-message.actions';
import {select, Store} from '@ngrx/store';
import * as state from '../../store/reducers/banner-reducer';
import * as domPurifier from 'dompurify';
import {DomSanitizer} from '@angular/platform-browser';
import {uniqueUrlValidator} from '../../shared/validator/unique-url.validator';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {NgbDateStruct, NgbModal, NgbModalRef, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {NgbdModalConfirmComponent} from '../../shared/ngbd-modal-confirm/ngbd-modal-confirm.component';
import {
  endDateRequiredWhenEndTimeSet,
  endDateTimeAfterStartDateTime,
  endTimeRequiredWhenEndDateSet,
  startDateTimeNotInPast
} from '../../shared/validator/date.validators';
import {getBannerMessageByID} from '../../store/selector/banner-message.selector';
import {
  convertHourMinuteToNgbTimeStruct,
  convertToDate,
  convertToNgbDateStruct,
  convertToNgbTimeStruct,
  isValidDate
} from '../../shared/utils/date-utils';
import {take} from 'rxjs/operators';
import {TimeValidators} from '../../shared/validator/time-validators/time.validators';
import {asteriskNumberValidator, urlIsInDomainValidator,
  asteriskCountZeroValidator} from '../../shared/validator/asterisks.validator';
import {LeadingZerosPipe} from '../../shared/pipe/leading-zeros.pipe';
import {excludeUrlValidator } from '@shared/validator/exclude-url.validator';

@Component({
  selector: 'banner-banner-message-form',
  templateUrl: './banner-message-form.component.html',
  styleUrls: ['./banner-message-form.component.scss']
})

export class BannerMessageFormComponent implements OnInit {

  form: UntypedFormGroup;
  isSubmitted = false;
  id: string = null;
  created = {by: null, on: null};
  modified = {by: null, on: null};
  isUpdated = false;
  addLeadingZeros = new LeadingZerosPipe();
  disableAddForExcludeUrl = true;

  constructor(
    public store: Store<state.State>,
    private domSanitizer: DomSanitizer,
    public location: Location,
    private modalService: NgbModal,
    private element: ElementRef,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.setupForm();
      if (this.id) {
        this.fillFormValues();
      }
    });
    this.form.valueChanges.subscribe(v => {
      this.isUpdated = true;
    });
  }

  /**
   * Used to support unit tests
   */
  getLocation(): Location {
    return this.location;
  }


  setupForm() {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl('',
        [Validators.required, Validators.maxLength(256)]),
      type: new UntypedFormControl('Notice', Validators.required),
      content: new UntypedFormControl('', Validators.required),
      active: new UntypedFormControl(true),
      canBeHidden: new UntypedFormControl(false),
      startDate: new UntypedFormControl(null, Validators.required),
      startTimeHour: new UntypedFormControl(null, [Validators.required, TimeValidators.twentyFourHour]),
      startTimeMinute: new UntypedFormControl(null, [Validators.required, TimeValidators.sixtyMinute]),
      endDate: new UntypedFormControl({value: null, disabled: true}),
      endTimeHour: new UntypedFormControl({value: null, disabled: true}, [TimeValidators.twentyFourHour]),
      endTimeMinute: new UntypedFormControl({value: null, disabled: true}, [TimeValidators.sixtyMinute]),
      urls: new UntypedFormArray([new UntypedFormGroup(this.createUrls())], uniqueUrlValidator),
      excludeUrls: new UntypedFormArray([], uniqueUrlValidator)
    },
    this.id ?
      [endDateRequiredWhenEndTimeSet, endTimeRequiredWhenEndDateSet, endDateTimeAfterStartDateTime, excludeUrlValidator] :
      [endDateRequiredWhenEndTimeSet, endTimeRequiredWhenEndDateSet, startDateTimeNotInPast,
        endDateTimeAfterStartDateTime, excludeUrlValidator]);
    this.form.controls.urls.valueChanges.subscribe(urls => {
      this.disableAddForExcludeUrl = !urls.filter((item) => item.url.indexOf('*') >= 0).length;
    });
  }

  /**
   * This method will default the form values with the banner message from the ngrx store.
   */
  fillFormValues() {
    this.store.pipe(
      select(getBannerMessageByID(this.id)),
      take(1))
      .subscribe((bm: BannerMessageModel) => {
        if (bm) {
          bm.fromDateUtc = new Date(bm.fromDateUtc);
          bm.toDateUtc = new Date(bm.toDateUtc);
          bm.createdUtc = new Date(bm.createdUtc);
          this.created.by = bm.createdBy;
          this.created.on = bm.createdUtc;
          bm.modifiedUtc = new Date(bm.modifiedUtc);
          this.modified.by = bm.modifiedBy;
          this.modified.on = bm.modifiedUtc;

          for (let i = 1; i < bm.urls.length; i++) {
            this.addUrl();
          }
          if (!bm.excludeUrls) {
            bm.excludeUrls = [];
          }
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < bm.excludeUrls.length; i++) {
            this.addExcludeUrl();
          }
          this.form.setValue({
            title: bm.title,
            type: bm.type,
            content: bm.content,
            active: bm.active,
            canBeHidden: bm.canBeHidden,
            startDate: isValidDate(bm.fromDateUtc) ? convertToNgbDateStruct(bm.fromDateUtc) : null,
            startTimeHour: isValidDate(bm.fromDateUtc) ? this.addLeadingZeros.transform(convertToNgbTimeStruct(bm.fromDateUtc).hour) : null,
            startTimeMinute: isValidDate(bm.fromDateUtc) ? this.addLeadingZeros.transform(convertToNgbTimeStruct(bm.fromDateUtc).minute) : null,
            endDate: isValidDate(bm.toDateUtc) ? convertToNgbDateStruct(bm.toDateUtc) : null,
            endTimeHour: isValidDate(bm.toDateUtc) ? this.addLeadingZeros.transform(convertToNgbTimeStruct(bm.toDateUtc).hour) : null,
            endTimeMinute: isValidDate(bm.toDateUtc) ? this.addLeadingZeros.transform(convertToNgbTimeStruct(bm.toDateUtc).minute) : null,
            urls: bm.urls.map(u => ({url: u})),
            excludeUrls: bm.excludeUrls.map(u => ({url: u})),
          });
        }
      });
    this.form.controls.endDate.enable();
    this.form.controls.endTimeHour.enable();
    this.form.controls.endTimeMinute.enable();
  }

  /**
   * create an instance of a banner detail.
   */
  createUrls(): any {
    return {
      url: new UntypedFormControl('', [
        Validators.required,
        asteriskNumberValidator,
        urlIsInDomainValidator
      ])
    };
  }

  createExcludeUrls(): any {
    return {
      url: new UntypedFormControl('', [
        Validators.required,
        asteriskCountZeroValidator
      ])
    };
  }

  /**
   * Add a details instance to form group
   */
  addUrl(): void {
    (this.form.controls.urls as UntypedFormArray).push(new UntypedFormGroup(this.createUrls()));
  }

  addExcludeUrl(): void {
    (this.form.controls.excludeUrls as UntypedFormArray).push(new UntypedFormGroup(this.createExcludeUrls()));
  }

  /**
   * remove the detail group at the index
   * @param i the index of the detail to remove
   */
  removeUrl(i: number): void {
    (this.form.controls.urls as UntypedFormArray).removeAt(i);
    this.form.markAsDirty();
  }

  removeExcludeUrl(i: number): void {
    (this.form.controls.excludeUrls as UntypedFormArray).removeAt(i);
    this.form.markAsDirty();
  }

  transformFormValueToBannerModel(): BannerMessageModel {
    const f = this.form.value;
    const banner = {
      title: f.title,
      type: f.type,
      content: this.purifyHTML(f.content),
      fromDateUtc: convertToDate(f.startDate, convertHourMinuteToNgbTimeStruct(f.startTimeHour, f.startTimeMinute)),
      toDateUtc: convertToDate(f.endDate, convertHourMinuteToNgbTimeStruct(f.endTimeHour, f.endTimeMinute)),
      active: f.active,
      canBeHidden: f.canBeHidden,
      urls: f.urls.map(u => u.url),
      excludeUrls: f.excludeUrls.map(u => u.url)
    } as BannerMessageModel;
    if (this.id) {
      banner.bannerMessageId = this.id;
    }
    return banner;
  }

  onCreate() {
    if (this.form.valid) {
      this.store.dispatch(new CreateBannerMessage(this.transformFormValueToBannerModel()));
    } else {
      this.focusFirstFormError();
    }
    this.isSubmitted = true;
  }

  focusFirstFormError() {
    const input = this.element.nativeElement
      .querySelectorAll(
        ':not(form):not(div):not(ngb-timepicker).ng-invalid, ngb-timepicker.ng-invalid input')[0];
    if (input) {
      input.focus();
    }
  }

  onUpdate() {
    const bannerMessage = this.transformFormValueToBannerModel();
    if (this.form.valid) {
      this.store.dispatch(new UpdateBannerMessage(bannerMessage));
    } else {
      this.focusFirstFormError();
    }
    this.isSubmitted = true;
  }

  purifyHTML(input: string): any {
    const domPurifyConfig = {
      ALLOWED_TAGS: [
        'div', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
        'p', 'b', 'a', 'u', 'ul', 'li', 'i', 'ol', 'img', 'svg'],
      ADD_ATTR: [
        'target', 'id', 'src', 'class', 'style', 'width', 'height',
        'href', 'src'
      ],
      USE_PROFILES: {
        svg: true,
        svgFilters: true,
        html: true
      }
    };
    return domPurifier.sanitize(input, domPurifyConfig).toString();
  }

  sanitizeHtml(input: string) {
    return this.domSanitizer.sanitize(SecurityContext.HTML, input);
  }

  getFormControl(c: string) {
    return this.form.get(c) as UntypedFormArray;
  }

  openConfirmDialog(): NgbModalRef {
    return this.modalService.open(NgbdModalConfirmComponent, {centered: true});
  }

  onCancel() {
    if (this.form.dirty) {
      // show confirm dialog
      this.openConfirmDialog().result.then((action: any) => {
        if (action === NgbdModalConfirmComponent.YES_ACTION) {
          this.location.back();
        }
      }, () => {
      });
    } else {
      this.location.back();
    }
  }

  isStartDateTimeValid(): boolean {
    const startDate: NgbDateStruct = this.form.controls.startDate.value as NgbDateStruct;
    const startTime: NgbTimeStruct = convertHourMinuteToNgbTimeStruct(
      this.form.controls.startTimeHour.value, this.form.controls.startTimeMinute.value);
    const startDateTime: Date = convertToDate(startDate, startTime);
    return (startDate !== null && startDate.year !== null) && (startDateTime !== null && startDateTime.toString() !== 'Invalid Date');
  }

  checkEndDates() {
    // if we have an id we're editing an existing banner message so never disable the endDate and endTime
    if (this.id) {
      return;
    }
    if (this.isStartDateTimeValid()) {
      this.form.controls.endDate.enable();
      this.form.controls.endTimeHour.enable();
      this.form.controls.endTimeMinute.enable();
    } else {
      this.form.controls.endDate.disable();
      this.form.controls.endTimeHour.disable();
      this.form.controls.endTimeMinute.disable();
    }
  }

  /**
   * only allows removal of urls, leaving at least one remaining.
   * @param i index of the url in formarray
   */
  shouldAllowDetailRemoval(i: number) {
    const urlArr = this.form.controls.urls as UntypedFormArray;
    return !(i === 0 && urlArr.controls.length === 1);
  }

  handleHourMinuteBlur(event: any) {
    this.form.controls[event.target.id].setValue(this.addLeadingZeros.transform(event.target.value));
  }
}
