import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {BannerMessageService} from '../../shared/banner-message.service';
import {BannerMessageModel} from '../../models/config-settings';
import { initialize } from '@element/notify';
import {
  CreateBannerMessageSuccess,
  CreateBannerMessageError,
  GetBannerMessagesSuccess,
  GetBannerMessagesError,
  BannerMessageActionTypes,
  UpdateBannerMessageSuccess,
  UpdateBannerMessageError,
  DeleteBannerMessageSuccess,
  DeleteBannerMessageError
} from '../../actions/banner-message/banner-message.actions';
import {Router} from '@angular/router';
import html from 'nanohtml';

@Injectable()
export class AppEffects {
  notifier: any;
  constructor(
    private actions$: Actions,
    private bannerService: BannerMessageService,
    private router: Router
  ) {
    this.notifier = initialize({
      duration: 3500,
      placement: 'bottom',
      el: document.body,
    });
  }

  @Effect()
  createBannerMessage$ = this.actions$.pipe(
    ofType(BannerMessageActionTypes.CreateBannerMessage),
    switchMap((action: any) =>
      this.bannerService.createBannerMessage(action.payload).pipe(
        map((rule: BannerMessageModel) => {
          return new CreateBannerMessageSuccess(rule);
        }),
        catchError(error => {
          return of(new CreateBannerMessageError(error));
        }),
      ),
    ),
  );

  @Effect ({ dispatch: false })
  createBannerMessageSuccess$ = this.actions$.pipe(
    ofType(BannerMessageActionTypes.CreateBannerMessageSuccess),
    tap((action: any) => {
      this.router.navigate(['/messages']);
      this.notifier.success(html`
            <svg class="element-notify-icon"><use xlink:href="#check"></use></svg>
            <p>The banner message ${action.payload.title} has been created successfully.</p>
            `
      );
    })
  );

  @Effect({ dispatch: false })
  createBannerMessageError$ = this.actions$.pipe(
    ofType(BannerMessageActionTypes.CreateBannerMessageError),
    tap((action: any) => {
      this.notifier.error('Error: Failed to create banner message.');
    })
  );

  @Effect()
  searchBannerMessages$ = this.actions$.pipe(
    ofType(BannerMessageActionTypes.GetBannerMessages),
    switchMap((action: any) =>
      this.bannerService.searchBannerMessages().pipe(
        map((bm: BannerMessageModel[]) => {
          return new GetBannerMessagesSuccess(bm);
        }),
        catchError(error => {
          return of(new GetBannerMessagesError(error));
        }),
      ),
    ),
  );

  /**
   * effect to call the service to update the banner message.
   */
  @Effect()
  updateBannerMessage$ = this.actions$.pipe(
    ofType(BannerMessageActionTypes.UpdateBannerMessage),
    switchMap((action: any) =>
    this.bannerService.updateBannerMessage(action.payload).pipe(
      map((bm: BannerMessageModel) => {
        return new UpdateBannerMessageSuccess(bm);
      }),
      catchError(error => of( new UpdateBannerMessageError(error)))
    ))
  );

  @Effect({ dispatch: false })
  updateBannerMessageSuccess$ = this.actions$.pipe(
    ofType(BannerMessageActionTypes.UpdateBannerMessageSuccess),
    tap((action: any) => {
      this.router.navigate(['/messages']);
      this.notifier.success(
        html `
            <svg class="element-notify-icon"><use xlink:href="#check"></use></svg>
            <p>The banner message ${action.payload.title} has been updated successfully.</p>
         `);
    })
  );

  @Effect({ dispatch: false})
  updateBannerMessageError$ = this.actions$.pipe(
    ofType(BannerMessageActionTypes.UpdateBannerMessageError),
    tap( (action: any) => {
      this.notifier.error('Error: Failed to update banner message.');
    })
  );

  /**
   * effect to call the service to delete the banner message.
   */
   @Effect()
   deleteBannerMessage$ = this.actions$.pipe(
     ofType(BannerMessageActionTypes.DeleteBannerMessage),
     switchMap((action: any) =>
     this.bannerService.deleteBannerMessage(action.payload).pipe(
       map((bm: BannerMessageModel) => {
         return new DeleteBannerMessageSuccess(bm);
       }),
       catchError(error => of( new DeleteBannerMessageError(error)))
     ))
   );
 
   @Effect({ dispatch: false })
   deleteBannerMessageSuccess$ = this.actions$.pipe(
     ofType(BannerMessageActionTypes.DeleteBannerMessageSuccess),
     tap((action: any) => {
      window.location.reload();
       this.notifier.success(
         html `
             <svg class="element-notify-icon"><use xlink:href="#check"></use></svg>
             <p>The banner message ${action.payload.title} has been deleted successfully.</p>
          `);
     })
   );
 
   @Effect({ dispatch: false})
   deleteBannerMessageError$ = this.actions$.pipe(
     ofType(BannerMessageActionTypes.DeleteBannerMessageError),
     tap( (action: any) => {
       this.notifier.error('Error: Failed to delete banner message.');
     })
   );
}
